import { DateTime } from 'luxon';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Table, Button, Form, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { createRiskFreePlays, getRiskFreePlays, getCampaign } from '../../../apis/campaigns.api';
import { useAuth } from '../../../contexts/auth.context';
import { formatDate } from '../../../utils/helpers';
import CampaignBreadcrumb from './CampaignBreadcrumbs';
import CreateRiskFreePlayModal from './CreateRiskFreePlaysModal';
import ExportCSVButton from './ExportButton';

const Campaign = () => {
  const { campaignId } = useParams();
  const [search, setSearch] = useState('');
  const [filteredRiskFreePlays, setFilteredRiskFreePlays] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [riskFreePlays, setRiskFreePlays] = useState([]);
  const [campaign, setCampaign] = useState(null);

  const { auth } = useAuth();
  const authToken = useRef(auth?.idToken);

  useEffect(() => {
    authToken.current = auth?.idToken;
  }, [auth?.idToken]);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const campaign = await getCampaign(campaignId);
        setCampaign(campaign);
      } catch (error) {
        console.error('Error fetching campaign data:', error);
      }
    };
    const fetchData = async () => {
      const response = await getRiskFreePlays(campaignId, authToken.current);
      setRiskFreePlays(
        response.map(rfp => ({
          ...rfp,
          solAmountString: `${rfp.solAmount} SOL`,
          playAmountString: `${rfp.playAmount ? `${rfp.playAmount} SOL` : ''}`,
        }))
      );
    };

    fetchData();
    fetchCampaign();
  }, [campaignId]);

  useEffect(() => {
    const filterRiskFreePlays = () => {
      const searchLower = search.toLowerCase();
      const filtered = riskFreePlays.filter(play =>
        JSON.stringify(play).toLowerCase().includes(searchLower)
      );
      setFilteredRiskFreePlays(filtered);
    };

    filterRiskFreePlays();
  }, [search, riskFreePlays]);

  const onCreateRiskFreePlays = async formData => {
    try {
      const response = await createRiskFreePlays(campaignId, formData, authToken.current);

      if (response) {
        console.log('Risk-free play created:', response);
        setRiskFreePlays([...response, ...riskFreePlays]);
      } else {
        const errorMessage = response;
        console.error('Error creating risk-free play:', errorMessage);
        alert('Error creating risk-free play. Please try again.');
      }
    } catch (error) {
      console.error('Error creating risk-free play:', error);
      alert('Error creating risk-free play. Please try again.');
    }
  };

  const getStatusBadge = status => {
    switch (status) {
      case 'REWARDED':
        return <Badge bg="success">REWARDED</Badge>;
      case 'REDEEMED':
        return <Badge bg="warning">REDEEMED</Badge>;
      case 'AVAILABLE':
        return <Badge bg="primary">AVAILABLE</Badge>;
      case 'EXPIRED':
        return <Badge bg="danger">EXPIRED</Badge>;
      case 'ACTIVATED':
        return <Badge bg="info">ACTIVATED</Badge>;
      // type
      case 'GOOD_TIL_WIN':
        return <Badge bg="secondary">Good 'til Win</Badge>;
      default:
        return <Badge bg="dark">ACTIVATED</Badge>;
    }
  };

  const renderStatusBadge = expireAt => {
    const isExpired = DateTime.fromISO(expireAt) < DateTime.utc();

    return <Badge bg={isExpired ? 'danger' : 'success'}>{isExpired ? 'Expired' : 'Active'}</Badge>;
  };

  return (
    <Container className="pt-3" style={{ overflow: 'scroll' }}>
      <CampaignBreadcrumb currentName={campaign?.name} />
      {campaign && (
        <>
          <Row className="my-4">
            <Col>
              <h2>{campaign?.name}</h2>
              <h6>Expires at {formatDate(campaign.expireAt)}</h6>
              <h6>Created by {campaign.createdBy}</h6>
              <h6>{renderStatusBadge(campaign.expireAt)}</h6>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Search Risk Free Plays"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </Col>
            <Col className="col-auto">
              <Button
                disabled={DateTime.fromISO(campaign?.expireAt) < DateTime.utc()}
                onClick={() => setShowModal(true)}
              >
                Create Risk Free Play
              </Button>
              <CreateRiskFreePlayModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onCreate={onCreateRiskFreePlays}
              />
            </Col>
            <Col className="col-auto">
              <ExportCSVButton data={filteredRiskFreePlays} />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Wallet ID</th>
                <th>Amount</th>
                <th>Played</th>
                <th>Game</th>
                <th>Status</th>
                {/* <th>Source</th> */}
                {/* <th>Created by</th> */}
                {/* <th>Type</th> */}
                <th>Tx</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {filteredRiskFreePlays.map(play => (
                <tr key={play.id}>
                  {/* <td>{play.id}</td> */}
                  {/* <td>{play.campaignName}</td> */}
                  <td>{play.walletId}</td>
                  <td>{play.solAmountString}</td>
                  <td>{play.playAmountString}</td>
                  <td>{play.gameId}</td>
                  <td>{getStatusBadge(play.status)}</td>
                  {/* <td>{play.source}</td> */}
                  {/* <td>{play.createdBy?.slice(0, 4)}</td> */}
                  {/* <td>{getStatusBadge(play?.type?.split('_').join(' ') ?? 'DEFAULT')}</td> */}
                  <td>
                    {play?.signature?.length > 0 ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://solscan.io/tx/${play.signature}`}
                      >
                        <i className="d-inline-flex fas fa-external-link-alt fa-xs"></i>
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>{play.reason?.slice(0, 10)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Campaign;

import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../../contexts/auth.context';
import PromoList from './PromoList';
import CreatePromoModal from './CreatePromoModal';
import { createPromo, getPromos } from '../../../apis/atlantis.api';

const Promos = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [promos, setPromos] = useState([]);
  const { auth } = useAuth();
  const authToken = useRef(auth?.idToken);

  useEffect(() => {
    authToken.current = auth?.idToken;
    fetchData();
  }, [auth?.idToken]);

  const fetchData = async () => {
    try {
      const items = await getPromos(authToken.current);
      setPromos(items.filter(i => i.status === 'ACTIVE'));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCreatePromo = async promoData => {
    try {
      await createPromo(promoData, authToken.current);
      await fetchData();
    } catch (error) {
      console.error('Error creating promo:', error);
    }
  };

  return (
    <div className="container pt-3" style={{ overflow: 'scroll' }}>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3 border-bottom">
        <h4 className="">Shop</h4>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => setShowCreateModal(true)}
            >
              Create
            </button>
          </div>
        </div>
      </div>
      <div>
        <PromoList cardItems={promos} />
      </div>
      <CreatePromoModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onCreate={handleCreatePromo}
      />
    </div>
  );
};

export default Promos;

export const CatGptItemSets = {
  CATGPT_3A: 'CATGPT_3A',
  CATGPT_3B: 'CATGPT_3B',
  CATGPT_3C: 'CATGPT_3C',
  CATGPT_3A_EXTREME: 'CATGPT_3A_EXTREME',
  CATGPT_3B_EXTREME: 'CATGPT_3B_EXTREME',
  CATGPT_3C_EXTREME: 'CATGPT_3C_EXTREME',
};

export const Game = {
  ID: 'CATGPT',
  OPTIONS: [
    CatGptItemSets.CATGPT_3A,
    CatGptItemSets.CATGPT_3A_EXTREME,
    CatGptItemSets.CATGPT_3B,
    CatGptItemSets.CATGPT_3B_EXTREME,
    CatGptItemSets.CATGPT_3C,
    CatGptItemSets.CATGPT_3C_EXTREME,
  ],
};

export const CoinOpStatus = {
  FINALIZED: 'FINALIZED',
  PROCESSING: 'PROCESSING',
  STARTED: 'STARTED',
};

export const FinaleText = [
  'Generating finale...',
  'Crafting your outcome...',
  'Creating your destiny...',
  'Calculating your conclusion...',
  'Constructing your finale...',
  'Determining your fate...',
  'Formulating your ending...',
  'Designing your final outcome...',
  'Forging your conclusion...',
  'Plotting your ultimate destiny...',
];

export const ChooseText = [
  `Select your path (A, B, or C):`,
  `Make a decision (A, B, or C):`,
  `Which path will you take (A, B, or C)?`,
  `Pick your choice (A, B, or C):`,
  `Time to choose (A, B, or C):`,
  `Your destiny awaits (A, B, or C):`,
  `Decide your fate (A, B, or C):`,
  `Which way will you go (A, B, or C)?`,
  `It's up to you now (A, B, or C):`,
  `The choice is yours (A, B, or C):`,
  'Pick your poison (A, B, or C):',
  'Roll the dice and choose (A, B, or C):',
  'Make your bet (A, B, or C):',
  'Trust your luck (A, B, or C):',
  'Go all-in and decide (A, B, or C):',
  'Take a chance and select (A, B, or C):',
];

// Array of messages for generating adventure
const StartText = [
  'Forging your epic journey...',
  'Unleashing your epic tale...',
  'Crafting your wild adventure...',
  'Formulating your heroic quest...',
  'Generating your daring escapade...',
  'Plotting your thrilling odyssey...',
  'Creating your fantastical saga...',
  'Calculating your epic adventure...',
  'Constructing your action-packed journey...',
  'Designing your unforgettable expedition...',
];

export const randomStartText = () => StartText[Math.floor(Math.random() * StartText.length)];
export const randomFinalText = () => FinaleText[Math.floor(Math.random() * FinaleText.length)];
export const randomChooseText = () => ChooseText[Math.floor(Math.random() * ChooseText.length)];

export const GAME_STEPS = {
  DEPOSIT: 'deposit',
  SETTING: 'setting',
  GAME_START: 'game-start',
  GAME_STEP_1: 'game-step-1',
  GAME_STEP_2: 'game-step-2',
  GAME_CONCLUSION: 'game-conclusion',
  GAME_REWARD: 'game-reward',
};

export const Steps = [
  GAME_STEPS.DEPOSIT,
  // GAME_STEPS.GAME_START,
  GAME_STEPS.GAME_STEP_1,
  // GAME_STEPS.GAME_STEP_2,
  GAME_STEPS.GAME_CONCLUSION,
  GAME_STEPS.GAME_REWARD,
];

export const PromoPageDetail = {
  id: 'promo',
  name: 'Shop',
  icon: 'fa-shop',
  path: '/promos',
  selectImageUrl: 'https://i.imgur.com/Th0L4Zi.png',
};

export const RFFPageDetail = {
  id: 'rff',
  name: 'Risk Free Plays (RFPs)',
  icon: 'fa-coins',
  path: '/campaigns',
  selectImageUrl: 'https://i.imgur.com/euEgkxy.png',
};

export const Pages = [PromoPageDetail, RFFPageDetail];

export const RewardType = {
  SOL: 'SOL',
  RFF: 'RFF',
  PHYSICAL_ITEM: 'PHYSICAL_ITEM',
  DIGITAL_ITEM: 'DIGITAL_ITEM',
  NFT: 'NFT',
  DISCORD_ITEM: 'DISCORD_ITEM',
  DISCORD_NITRO: 'DISCORD_NITRO',
};

export const PromoType = {
  FRAGMENT: 'FRAGMENT',
  RAFFLE: 'RAFFLE',
  LOSER: 'LOSER',
};
